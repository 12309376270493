.deletebtn{
    background: red;
    outline: none;
    border-width: 0px;
    border-radius: 8px;
    margin-top: 2px;
    color: white;
    padding: 8px;

}

.deletebtn:hover{
    color: black;
    opacity: 0.8;
}