.colordiv{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
}

.colorbody{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.colorbtn {
  margin: 10px;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #1940f0;
  background-image: linear-gradient(45deg, #A020F0 0%, #3c60ff  51%, #A020F0  100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.colorbtn {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

.colorbtn:active {
  transform: scale(0.95);
}