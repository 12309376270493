:root {
    --primary: #871D85
 }


.mybuttons{
    width: 200px;
    background: var(--primary);
    padding: 12px;
    color: white;
    outline: none;
    margin-top: 8px;
    border-width: 0px;
    border-radius: 8px;
}