:root {
   --primary: #871D85
}
*{
    margin: 0;
    padding: 0;
}
.mymaindiv{
    background: #fff;
}
.mychilddiv{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.mywrapperdiv{

    display: flex;
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 20px 35px rgb(0, 0, 0, 0.1);
}
.mywrapper{
    width: 400px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
    background: #871D85;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 20px 35px rgb(0, 0, 0, 0.1);
}

.myusername{
    width: 100%;
    margin-top: 20px;
    padding: 10px 20px 10px 20px;
    border:none;
    outline-width: 0;
    border: none;
    border-radius: 20px;
}
.myuserpassword{
    width: 90%;
    margin-top: 20px;
    padding: 10px 20px 10px 20px;
    border:none;
    outline-width: 0;
    border: none;
    border-radius: 4px;

}
.myadduserbtn{
    width: 60%;
    margin-top: 40px;
    padding: 10px 0 10px 0;
    border-radius: 18px;
    font-weight: bold;
    border: 2px solid #fff;
    color: #fff;
    background: #871D85;
}
.myadduserbtn:hover{
    border: 2px solid #fff;
    color: #871D85;
    background: #fff;
}