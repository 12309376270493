
  
  .sidenav {
    /* height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #cdb2fd;
    overflow-x: hidden;
    transition: 0.6s;
    padding-top: 60px; */

    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #cdb2fd;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
   
   

  }


  .icondiv :hover{
      cursor: pointer
  }
  
  .icondiv {
      display: flex;
      align-items: center;
      padding: 8px;
      margin-left: 12px;
   
  }
  .myicon{
    color: white;
    margin: 12px;
  }

  #main {
    transition: margin-left .5s;
  
    /* padding: 16px; */
  }
  .sidenav a {
   
    text-decoration: none;
    font-size: 20px;
    color: white;
    
    display: block;
    transition: 0.3s;
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }

  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }