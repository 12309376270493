/* Style inputs, select elements and textareas */
input[type=text], select, textarea{
    width:100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
  }
  input[type=number], select, textarea{
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
  }
  
  /* Style the label to display next to the inputs */
  label {
  margin-top: 12px;
    font-size: 10px;
    display: inline-block;
    text-align: left;
  }
 
  
  /* Style the container */
  .container {

    display: flex;
    background-color: #b2acac17;

    flex-direction: column;
    padding-bottom: 12px;
    align-items: center;
    justify-content: center;
  }
  
 
  
  /* Clear floats after the columns */


.mybuttons1{
    width: 150px;
    align-items: flex-end;
   justify-content: space-around;
    background: var(--primary);
    padding: 12px;
    color: white;
    outline: none;
    border-width: 0px;
    border-radius: 8px;
}
  
  /* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
 


 