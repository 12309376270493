* {
   margin: 0;
   padding: 0;
}

:root {
   --darkblue : #6200EE;
   --lightblue : #E1D0FF;
}

body {
   background: #fff;
}

section {
   display: flex;
   justify-content: center;
   margin-top: 2rem;
}

.box-div2{
   width: 90%;
}

.buttons2{
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-top: 2rem;
}

.member2{
   font-family: 'Montserrat', sans-serif;
   font-weight:  600;
   font-size: 16px;
   margin-right: 10px;
}

.search-input{
   margin-right: 20px;
   border: 1px solid var(--lightblue);
   background: #fff;
   border-radius: 4px;
   
   padding: 5px 10px;
   font-family: 'Nunito Sans', sans-serif;
   font-size: 13px;
   outline: none;
}

.btn2{
   background: #fff;
   color: rgb(83, 83, 83);
   border: 1px solid #d6d4d4;
   font-family: 'Nunito Sans', sans-serif;
   font-weight: bold;
   font-size: 12px;
   padding: 5px 20px;
   border-radius: 4px;
   margin-right: 10px;
}

.btn2:hover{
   background: var(--darkblue);
   color: #fff;
   border: 1px solid #f0efef;
}

.thead{
   background: var(--lightblue);


}

.tablehead2 th{
   color: #000;
}


.tablebox2 {
   width: 100%;
   margin: 0;
   margin-top: 2rem;
   border: 1px solid #f0efef;
   background-color: #fff;
   border-radius: 2px;
   padding: 0;
}

.tablerow2 {
   display: table;
   width: 100%;
   background: transparent;
   cursor: pointer;
   margin: 0;
   border-top: 1px solid #eeeded;
   padding: 12px;
}

.tablerow2 th{
   display: table-cell;
   width: 12%;
   text-align: center;
   cursor: pointer;
   color: #000;
 
   vertical-align: middle;
   font-family: 'Nunito Sans', sans-serif;
   font-size: 13px;
   font-weight: 500;
}

.tablehead2 .tablecell2 {
   border-right: none;
   color: rgb(166, 164, 164);
   border-radius: 34px;
   font-family: 'Montserrat', sans-serif;
   font-weight:  600;
   font-size: 12px;
}

.tablehead2 th{
   display: table-cell;
   width: 12%;
   text-align: center;
   cursor: pointer;
   color: #575757;
   font-weight: bold;

   font-family: 'Nunito Sans', sans-serif;
   font-size: 12px;
   font-weight: bold;
}

.tablehead2 {
   background: #fff;
   box-shadow: none;
   font-weight: 600;
   padding: 5px 0;
   display: table;
   width: 100%;
   background: transparent;
   cursor: pointer;
   margin: 0;
 
}



.tags2{
   display: flex;
   align-items: center;
}

.tag2{
   font-size: 12px;
   color: #000;
   padding: 5px 10px 5px 10px;
   font-family: 'Nunito Sans', sans-serif;
   background: #f2f1f1;
   border-radius: 32px;
   margin-right: 5px;
}

.icon2{
   padding: 5px 10px 5px 10px;
   background: #665feb;
   border-radius: 4px;
   margin-left: 2px;
   color: #fff;
}

.i-con2{
   padding: 5px 10px 5px 10px;
   background: #f04f4f;
   border-radius: 4px;
   color: #fff;
}

.tablebtn2 {
   width: 80px;
   padding: 5px 0 5px 0;
   border-radius: 18px;
   font-weight: bold;
   border: 2px solid var(--darkblue);
   color: #fff;
   background: var(--darkblue);
}

.tablebtn2:hover {
   border: 2px solid var(--darkblue);
   color: var(--darkblue);
   background: #fff;
}

.doc-img2{
   width: 60px;
   height: 70px;
   object-fit: cover;
   border-radius: 4px;
   border: 3px solid rgb(205, 204, 204);
   margin: 0;
   padding: 0;
}